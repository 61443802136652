.showSolution .content{
    padding: 30px 0;
}

.showSolution .verificatorList{
    margin: 20px auto;
    display: table;

}

.showSolution .verificatorList .row{
    height: 50px;
    text-align: right;
    display: table-row;

}

.showSolution .verificatorList .row .cell.first{
    width: 40px;
    text-align: left;
}
.showSolution .verificatorList .row .cell{
    display: table-cell;
    vertical-align: middle;
    height: 50px;
    width: 150px;
    padding:5px;

}


.showSolution .verificatorList .row .cell .spot{
    text-transform: uppercase;
    color: #2db563;
    font-size: 35px;
    font-weight: bolder;
}

.showSolution .verificatorList .row .cell img{
    height: 50px;

}