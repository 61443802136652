.copyButton {
    background: black;
    color: #ffffff;
    display: inline-block;
    font-size: 15px;
    font-weight: bold;
    margin-top: -10px;
    vertical-align: middle;
    height: 30px;
    line-height: 30px;
    padding: 0 10px;
    border: none;
    border-radius: 15px;
    cursor: pointer;
}

.copyButton .icon{
    padding-right: 4px;
}
