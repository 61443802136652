.pageInGame h2{
    margin: 10px;
    font-size: 25px;
}

.pageInGame .solomode,
.pageInGame .solomode span,
.pageInGame .difficulty,
.pageInGame .difficulty span{
    font-size: small;
}
.pageInGame .row{
    text-align: center;
    vertical-align: center;

    padding: 6px;
    margin: auto;
}
.pageInGame .row:first-child{
}

.pageInGame .row .spot{
    text-transform: uppercase;
    color: #2db563;
    text-align: left;
    display: inline-block;
    font-size: 35px;
    font-weight: bolder;
    transform: translateY(3px);
    margin-right: 25px;

}
.pageInGame .row .cell{
    display: inline-block;

}

.pageInGame .mixedcriteria{
    margin: 40px 0;
}
.pageInGame .actions{
    margin: 30px 0;
}
