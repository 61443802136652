.mainPageImage{
    width: 100%;
    max-width: 600px;
}

h2{
    margin-bottom: 80px;
}

a.button.homeButton,
input[type=button].homeButton{
    font-size: 16px;
    font-weight: bold;

    position: relative;
}

.new{
    position: absolute;
    right: 5px;
    top: 8px;
    background: #f39304;
    color: #ffffff;
    border-radius: 15px;
    padding: 0 5px;
    font-size: 10px;
    line-height: 20px;
    font-weight: normal;
}

a{
    display: block;
    width: 85%;
    margin: 15px auto;
    text-align: center;
    font-size: 14px;
}

.separator{
    width: 15%;
    border-bottom: 1px solid #ececec;
    margin: 20px  auto;
}