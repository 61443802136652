
.lang-switch{
    width: 150px;
    text-align: right;
    margin: auto;
    background: #ffffff;
}
.lang-switch #imageDropdown{
    white-space: nowrap;
    cursor: pointer;
    width: 150px;
    background: #ffffff;
}

.lang-switch #imageDropdown:after{
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: "";
    border-top: 0.3em solid;
    border-right: 0.3em solid transparent;
    border-bottom: 0;
    border-left: 0.3em solid transparent;
}
.lang-list{
    display: none;
    color: #ffffff;
    text-align: right;
    width: 150px;
    position: absolute;
    background: #ffffff;
    z-index: 1;
}
.lang-switch a{
    text-decoration: none;
    margin:0;
    padding: 3px;
    width:100%;
    cursor: pointer;
}