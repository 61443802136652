.pageResultMachine h2.failure{
    color: #d01B1b;
}

.pageResultMachine h2.success{
    color: #2db563;
}

.pageResultMachine .imageContainer{
    width: 148px;
    height: 148px;
    border-radius: 15px;
    padding: 8px;
    margin:auto;
}
.pageResultMachine .imageContainer.success{ background: #2db563;}
.pageResultMachine .imageContainer.failure{ background: #d01b1b;}

.pageResultMachine .face{
    width: 100%;
    margin: 0;
    padding: 0;
}
.pageResultMachine .hash{
    margin:10px;
}
.pageResultMachine .separator{
    margin: 35px auto;
    width: 100%;
    border-bottom: 1px solid #dddddd;
}

.pageResultMachine .shareLabel{
    padding-bottom: 15px;
}


.pageResultMachine .answerGrid{
    display: table;
    margin: auto;
}

.pageResultMachine .answerGrid .row{
    display: table-row;
}

.pageResultMachine .answerGrid .row .cell{
    display: table-cell;
    width:10px;
}

.pageResultMachine .answerGrid .row .cell.letter{
    text-transform: uppercase;
    color: #2db563;
    font-size: 35px;
    font-weight: bolder;
}
