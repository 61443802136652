h2{
    margin-bottom: 20px;
}

.mainTab{
    text-align: center;
}

.codeDisplayer{
    height: 40px;
}

.pageInputCode .codeDisplayer.code .digit{
    display: inline-block;
    text-align: center;
    width: 50px;
    font-size: 30px;
}

.codeInserter{
    margin: auto;
    margin-bottom: 15px;
    display: inline-block;
}

.codeMessage{
    margin-bottom: 15px;
}

.wrongCode{
    color: #d01B1b;
    margin-bottom: 5px;
}
.correctCode{
    color: #2db563;
}
