.cardpicker{
    width:100%;
    display: inline-block;
}

.cardList .card{
    position: relative;
    width: 46%;
    float:left;
    margin: 5px;

    cursor: pointer;

}
.cardList .card .imgCard{
    width: 100%;
    border: 2px solid #000000;
    border-radius: 10px;
}


.cardList .card .cardRemove{
    position: absolute;
    top: -5px;
    right: -5px;

    width: 25px;
    height: 25px;
    background: #ff5900;
    border-radius: 15px;
    cursor: pointer;

    color: white;
    font-weight: lighter;
    font-size: 25px;
    padding: 0;
    line-height: 26px;
}

.cardList .card.openListing{
    cursor: pointer;
    background: #ececec;
    overflow: hidden;

    border-radius: 10px;
    min-height: 110px;
    max-height: 110px;
    text-align: center;
    padding: 20px 5px;
}

.cardList .card.openListing label{
    cursor: pointer;
    width: 100%;
    display: table;
    vertical-align: middle;
}

.cardList .placeholder{
    cursor: pointer;
    width: 100%;
    height: 200px;
    vertical-align: middle;
    background: #ececec;
    display: flex;
    align-items: center;
    justify-content: center;
    paddin-g: 0 15px;
    margin-top:0;

    border-radius: 25px;
}
.cardList .placeholder label{
    cursor: pointer;
    text-align: center;
    margin: auto;

}

.cardListing{
    height: 100vh;
    padding-bottom: 30px;
    overflow-y: scroll;
    position: absolute;
    top: 0;
    right:0;
    width: 100%;
    background: #ffffff;
    z-index: 100;
}

.cardListing .card{
    display: table-row;
    cursor: pointer;
}
.cardListing .holder
{
    display: table-cell;
    width: 85%;
    text-align:center;
    padding: 3px 0;
}
.cardListing .holder .imgCard{
    width: 90%;
    border: 2px solid #000000;
    border-radius: 15px;

}

.cardListing h1{
    margin-right: 50px;
    margin-left: 50px;
}

.cardListing .close{
    font-weight: lighter;
    font-size: 5vh;
    width: 30px;
    height: 40px;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
}

